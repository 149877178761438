// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import SectionWrapper from '../components/SectionWrapper';
import PageHelmet from '../plugins/helmet/components/PageHelmet';
import RichText from '../plugins/prismic/components/RichText';
import Link from '../plugins/i18n/components/Link';
import type { Query } from '../schema.flow';

const styles = (theme: Theme) => ({
  root: {},
  wrapper: {
    height: '100vh',
    display: 'flex',
    paddingLeft: theme.spacing.unit * 4,
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textAlign: 'center',
    margin: 'auto',
  },
  capitalText: {
    textTransform: 'uppercase',
  },
  link: {},
  button: {
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  data: Query,
};

const NotFoundPage = ({ classes, data: query }: Props) => (
  <SectionWrapper
    limitWidth
    guttersH
    guttersV
    classes={{ root: classes.root, wrapper: classes.wrapper }}
  >
    <PageHelmet data={query.prismic404Page && query.prismic404Page.data} />
    <div className={classes.container}>
      {query.prismic404Page?.data?.title?.text ? (
        <Typography variant="h1" className={classes.capitalText}>
          {query.prismic404Page?.data?.title?.text}
        </Typography>
      ) : null}
      {RichText.hasValue(query.prismic404Page?.data?.description) ? (
        <Typography
          component="div"
          variant="h5"
          gutterBottom
          className={classes.capitalText}
        >
          <RichText {...query.prismic404Page?.data?.description} />
        </Typography>
      ) : null}
      {query.prismic404Page?.data?.button_home?.text ? (
        <Link className={classes.link} to="/">
          <Button
            aria-label="Back home icon"
            variant="outlined"
            color="inherit"
            disableRipple
            className={classes.button}
          >
            {query.prismic404Page?.data?.button_home?.text || ''}
          </Button>
        </Link>
      ) : null}
    </div>
  </SectionWrapper>
);

export default withStyles(styles)(NotFoundPage);

export const query = graphql`
  query NotFoundPageQuery($prismicLocaleId: String) {
    prismic404Page(lang: { eq: $prismicLocaleId }) {
      id
      lang
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        title {
          text
        }
        description {
          text
          html
        }
        button_home {
          text
        }
      }
    }
  }
`;
